$primary: #20ff00; /* MAIN COLOR */
$secondary: #20ff00; /* SECONDARY COLOR */
$modalbox:#21ff02;
$color1: #000FFF; 
$color2: #FF1000;
$color3: #00FF20;
$color4: #FFC700;
$blk: #000; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fff; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// @import url('https://fonts.googleapis.com/css?family=Roboto:900|Ubuntu:300');
@import url('https://fonts.googleapis.com/css?family=Permanent+Marker|Quicksand');

html, body {
	height: 100%;
}

body {
	font-size: 125%;
	@media (max-width: 991px) {
		font-size: 115%;
	}
}

h1, h2, h3 {
	font-family: 'Permanent Marker', cursive;
	// text-transform: uppercase;
}
p {
	font-family: 'Quicksand', sans-serif;
	-webkit-margin-before: 0px;
	-webkit-margin-after: 0px;
	font-size: 1.2em;
}
.flash {
	display:none;
}

nav {
	z-index: 1000;
}

nav.navbar.navbar-default {
	background: #fff;
	border: transparent;
}
.logo {
	max-width: 200px;
	width: 100%;
	padding: 10px;
	@media (max-width: 767px) {
		max-width: 150px;
	}
}
.nav>li>a {
	@media (max-width: 767px) {
		padding: 10px 0;
	}
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		color: $blk;
		margin-top: 17px;
		background: transparent;
		font-size: 1.2em;

	    &:hover {
	    	color: $secondary !important;
	    }

	    &:visited,&:focus,&:active {
		  color: $blk;
		  outline: 0;
		}		

		@media (max-width: 767px) {
			margin-top: 0;
		}
	}
}


.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 991px) {
		margin-top: 20px;
		background: $modalbox;


	}
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: none;
	border-radius: $border-radius;
	background: #18c100;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($modalbox,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: #18c100;
		
		h2 {
			text-align:center;
		}
		input#username, input#password {
			@media (max-width: 767px) {
				font-size: 16px;
			}
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($color1,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			// text-shadow: 0px 0px 3px $blk;
			outline: 0;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 50%;	
	}

}

.modal-header, .modal-footer {
	background: #18c100;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 40px 0px 20px;
	background: $wht;
	color: $blk;
	font-size: 1em;
	outline: 0;
	
	a {
		color: $blk;
		text-decoration: none;

	&:hover,&:focus,&:visited,&:active {
	    	color: $blk;
	    	text-decoration: none;
	    	outline: 0;
	    }
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}

.slideOne {
	background: linear-gradient(
        rgba(0,0,0,0.6),
        rgba(0,0,0,0.6)
		),
	url(../img/slideOne.jpg) no-repeat;
	background-size: cover;
	background-position: 50% 55%;
	height: 700px;
	display: flex;
	align-items: center;
		h1 {
			color: white;
			font-size: 6em;
			@media (max-width: 991px) {
				font-size: 4em;
			}
			@media (max-width: 767px) {
				font-size: 2.5em;
			}
		}
}

.slideTwo {
	background: linear-gradient(
        rgba(0,0,0,0.6),
        rgba(0,0,0,0.6)
		),
	url(../img/slideTwo.jpg) no-repeat;
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: 50% 25%;
	height: 700px;
		h1 {
			color: white;
			font-size: 6em;
			@media (max-width: 991px) {
				font-size: 4em;
			}
			@media (max-width: 767px) {
				font-size: 2.5em;
			}
		}
}

.slideThree {
	background: linear-gradient(
        rgba(0,0,0,0.6),
        rgba(0,0,0,0.6)
		),
	url(../img/slideThree.jpg) no-repeat;
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: 50% 25%;
	height: 700px;
		h1 {
			color: white;
			font-size: 6em;
			@media (max-width: 991px) {
				font-size: 4em;
			}
			@media (max-width: 767px) {
				font-size: 2.5em;
			}
		}
}

.slideOne, .slideTwo, .slideThree {
	@media (max-width: 991px) {
		background-position: 70% 50%;
		height: 500px;
	}
	@media (max-width: 767px) {
		height: 300px;
	}
}

.navbar {
	margin-bottom: 0;
}

i.fa {
	display: block;
	margin: 20px auto;
	font-size: 5em;
	max-width: 75px;
	width: 100%;
	text-align: center;
	color: $wht;
	@media (max-width: 991px) {
		margin: 0 auto;
		padding: 20px;
		max-width: 200px;
	}
}

.iconContain {
	text-align: center;
	transition: all 0.3s ease-in-out;
	min-height: 270px;
	h2 {
		color: $wht;
	}
	p {
		padding: 20px;
		font-size: 1.2em;
		color: $blk
	}
}

.iconContain:nth-child(1) {
	background: #00c2ff;
		
	&:hover {
		background: #0080b3;
	}
}
.iconContain:nth-child(2) {
	background: #e20000;
	&:hover {
	background: #bb0c00;
	}
}
.iconContain:nth-child(3) {
	background: #00d01a;
	&:hover {
	background: #00a915;
	}
}
.iconContain:nth-child(4) {
	background: #FFC700;
	&:hover {
	background: #cc9f00;
	}
}
.bg {
	background: #e2e2e2;
	p {
		@media (max-width: 991px) {
		width: 70%;
		margin: 0 auto;
	}
	@media (max-width: 600px) {
		width: 100%;
	}
	}
}
.bg2 {
	background: #df00ff;
	position: relative;

	.txt {
		padding: 60px 25px 0px 60px;

		h1 {
			color:$wht;
		}

		p{
			color:$blk;
		 	font-size: 1.4em;
		}

		@media (max-width: 767px) {
			padding: 30px;
		}
	}
}
.bg3 {
	background: $blk;
	position: relative;
	height: 1000px;

	.txt {
		padding: 50px;
		h1 {
			color: white;
		}
		p {
			font-size: 1.2em;
			color: $wht;
		}
		@media (max-width: 991px) {
			padding: 20px;
		}
	}
	.txtBox1 {
		@media (max-width: 991px) {
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.txtBox2 {
		@media (max-width: 991px) {
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.wrapper, .wrapper2 {
		height: 500px;
		position: relative;
		.container, .row {
			height: 100%;
		}
	}
	.wrapper {
		@media (max-width: 991px) {
			background: linear-gradient(
				rgba(223, 0, 255, 0.93),
				rgba(255, 0, 94, 0.15)
				),
			url(../img/bgImg1.jpg) no-repeat;
			background-position: 50% 50%;
			background-size: cover;
			z-index: 100;
		}
		.bgImg {
			background: url(../img/bgImg1.jpg) no-repeat;
			background-size: cover;
			height: 500px;
			width: 50%;
			position: absolute;
			background-position: 50% 27%;
			top: 0;
			right: 0;
			z-index: 0;
		    &:hover .overlay {
		    	opacity: 1;
		    	@media (max-width: 991px) {
		    		opacity: 0;
		    	}
		    }
			@media (max-width: 991px) {
			background: none;
			}
		}
		.overlay {
			opacity: 0;
			height: 100%;
			width: 100%;
			transition: all 0.5s ease-in-out;
			background: rgba(0,0,0,0.8);
			position: relative;
			z-index: 1;
			display: none;
			h1 {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
				color: white;
			}
		}
	}
	.wrapper2 {
		@media (max-width: 991px) {
			background: linear-gradient(
				rgba(0, 235, 255, 0.33),
				rgba(0, 196, 255, 0.75)
				),
			url(../img/bgImg2.jpg) no-repeat;
			background-size: cover;
		}
		.bgImg {
			background: url(../img/bgImg2.jpg) no-repeat;
			background-size: cover;
			height: 500px;
			width: 50%;
			position: absolute;
			background-position: 50% 50%;
			top: 0;
			left: 0;

			@media (max-width: 991px) {
				background: none;
				width: 100%;
			}
			  &:hover .overlay {
		    	opacity: 1;
		    	@media (max-width: 991px) {
		    		opacity: 0;
		    	}
		    }
		}
		.overlay {
			opacity: 0;
			height: 100%;
			width: 100%;
			transition: all 0.5s ease-in-out;
			background: rgba(0,0,0,0.8);
			position: relative;
			z-index: 1;
			display: none;
			h1 {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
				color: white;
			}
		}
	}
}

.btn {
  background-color: #1cde00;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;
  z-index: 2000;

 	&:hover {
 		background-color: $wht; 
  		//border-color: rgba(255,255,255,1); 
  		color: $blk;
	}
}


@media only screen 
and (min-device-width : 300px) 
and (max-device-width : 1024px) {
    a:hover,a:focus {
     outline: 0 !important;
     background: transparent !important;
     color: #000 !important;
    }
}